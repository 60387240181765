import './App.css'
import 'flag-icons'
import './i18n/config'
import { Main } from './views/main'

function App() {
  return (
    <div className="app">
      <Main />
    </div>
  )
}

export default App
